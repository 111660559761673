<template>
  <div v-if="currentRestaurant" id="app">
    <header-menu></header-menu>
    <!--<div v-if="visibleHeader" class="utils--spacer-header"></div>-->
    <router-view/>
    <!--<footer-menu></footer-menu>-->
    <!--<options></options>-->
  </div>
</template>
<script>
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import headerMenu from './views/elements/Header.vue';
import footerMenu from './views/elements/Footer.vue';
import options    from './views/elements/Options.vue';

import { mapGetters, mapActions, mapState } from 'vuex';
export default {
  components: {
    headerMenu,
    footerMenu,
    options
  },
  computed: {
    ...mapGetters('restaurants', { restaurantById: 'byId' }),
    ...mapState(['route']),
    currentRestaurant() { return this.restaurantById(process.env.VUE_APP_RESTAURANT_ID); }
  },
  methods: {
    ...mapActions('restaurants', { fetchRestaurant: 'fetchSingle' }),
    fetchData() { return this.fetchRestaurant({ id: process.env.VUE_APP_RESTAURANT_ID }); },
  },
  watch: {
    $route: 'fetchData',
  },
  created() {
    this.fetchData();
  },
  data: function () {
    return {
      lang: 'fr_CH',
      visibleHeader: true,
      statics: {
        headerLngSwitch: { fr_CH: 'english', en_GB: 'français', },
        headerSelection: { fr_CH: 'Ma sélection', en_GB: 'My Selection' },
        categoryPrice: { fr_CH: 'prix CHF', en_GB: 'price CHF' },
        categoryChoose: { fr_CH: 'choisir', en_GB: 'choose' },
        selectionClose: { fr_CH: 'fermer', en_GB: 'close'},
        selectionTitle: { fr_CH: 'vous avez choisi', en_GB: ' you choose'},
        selectionReturn: { fr_CH: 'retour', en_GB: 'return'},
        selectionEmpty: { fr_CH: 'Aucun élément sélectionné', en_GB: 'No items selected'},
        selectionAdd: {fr_CH: 'ajouter à la sélection', en_GB: 'add to selection'},
        drinksTitle: { fr_CH: 'Les boissons', en_GB: 'Drinks'},
        winesTitle: { fr_CH: 'Les vins', en_GB: 'Wines' },
        specialTitle: { fr_CH: 'menu spécial', en_GB: 'special menu' },
        menuTitle: { fr_CH: 'Cartes', en_GB: 'Menus'},
        filterTitle: { fr_CH: 'Filtrer', en_GB: 'Filter'},
        filterAll: { fr_CH: 'Tous', en_GB: 'All'},
      },
      selectedProducts: []
    }
  }
}
</script>
<style lang="scss">
  @import './assets/css/animate.css';
  @import '@/assets/scss/theme.scss';
</style>
