<template lang="html">
  <header v-if="$parent.visibleHeader">
    <div>
      <a class="pointer" v-if="isHome()" @click="lngSwitch()">
        <b>{{$parent.statics.headerLngSwitch[$parent.lang]}}</b>
      </a>
      <a class="pointer" v-else @click="goBack">
        <b><i class="material-icons">chevron_left</i>{{$parent.statics.selectionReturn[$parent.lang]}}</b>
      </a>
    </div>
    <div>

    </div>
    <div>

    </div>
  </header>
</template>
<script>
export default {
  methods: {
    lngSwitch: function(){
      this.$parent.lang = (this.$parent.lang == 'fr_CH')? 'en_GB' : 'fr_CH'
    },
    goBack: function() {
      window.history.length > 1
        ? this.$router.go(-1)
        : this.$router.push('/')
    },
    isHome: function() {
      return (this.$route.name == 'home')
    }
  },
}
</script>
