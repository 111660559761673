import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import vueSmoothScroll from 'vue2-smooth-scroll'

Vue.config.productionTip = false
Vue.use(vueSmoothScroll)
Vue.directive('scroll', {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})
Vue.filter('lang', function (elem, key, lng) {
  if(lng !== 'fr_CH' &&
     elem.hasOwnProperty("_translations") &&
     elem['_translations'].length == undefined){
       return (elem['_translations'][lng][key].length > 0)? elem['_translations'][lng][key] : elem[key]
     }else{
       return elem[key]
     }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
